<!--结账完成产生订单并显示-->
<template>
    <div class="main-content ">
        <el-row class="cart-header">
            <el-col :lg="3" :md="3" class="hidden-sm-and-down">
                &nbsp;
            </el-col>
            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                <el-steps :active="3" finish-status="success" simple style="margin-top: 20px;margin-right: 1%;margin-left: 1%">
                    <el-step title="購物車"></el-step>
                    <el-step title="結帳"></el-step>
                    <el-step title="訂單完成"></el-step>
                </el-steps>
            </el-col>
            <el-col :lg="3" :md="3" class="hidden-sm-and-down">
                &nbsp;
            </el-col>
        </el-row>
        <el-row >
            <el-col :lg="3" :md="3" class="hidden-sm-and-down">
                &nbsp;
            </el-col>

            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                <el-row style="margin: auto 1%;text-align: center;">
                <el-button plain type="success" style="width: 100%;border-style: dashed;border-width: 2px;padding: 20px;font-size: 28px;text-align: center;">
                    <!--<span v-if="orderInfo.paid">訂單正在加急處理中...</span>
                        <span v-else>謝謝，我們已收到您的訂單!</span>-->
                    <span>{{orderInfo.orderStatusStr}}</span>
                </el-button>
                </el-row>
                <el-row type="flex" justify="start" style="flex-wrap: wrap">
                    <el-col :lg="6" :md="6" :sm="12" :xs="12" class="order-info-card"><p>訂單編號</p><h4>{{orderInfo.orderId}}</h4></el-col>
                    <el-col :lg="6" :md="6" :sm="12" :xs="12" class="order-info-card"><p>日期</p><h4>{{orderInfo.createTime}}</h4></el-col>
                    <el-col :lg="6" :md="6" :sm="12" :xs="12" class="order-info-card"><p>總計</p><h4>{{orderInfo.payPrice|filter_addPricePrefix}}</h4></el-col>
                    <el-col :lg="6" :md="6" :sm="12" :xs="12" class="order-info-card"><p>付款方式</p><h4>數支富{{orderInfo.payTypeCode=='ATM'?'ATM取號付款':'超商繳款'}}</h4></el-col>
                </el-row>

                <el-card style="margin: 10px;" class="cart-total-card" shadow="hover">
                    <el-row>
                        <el-col><h3 style="margin-bottom: 0px">付款資訊</h3>
                        </el-col>
                    </el-row>
                    <el-row type="flex" justify="start" style="flex-wrap: wrap">
                        <el-col :lg="6" :md="6" :sm="12" :xs="12" class="order-info-card"><p>{{orderInfo.payTypeCode=='ATM'?'匯款銀行':'超商'}}</p><h4>{{orderInfo.tradeBankName}}</h4></el-col>
                        <el-col :lg="6" :md="6" :sm="12" :xs="12" class="order-info-card"><p>{{orderInfo.payTypeCode=='ATM'?'匯款帳號':'繳費代碼'}}</p><h4>{{orderInfo.paymentCode}}</h4></el-col>
                        <el-col :lg="6" :md="6" :sm="12" :xs="12" class="order-info-card"><p>{{orderInfo.payTypeCode=='ATM'?'銀行代號':'超商編號'}}</p><h4>{{orderInfo.tradeBankNo}}</h4></el-col>
                        <el-col :lg="6" :md="6" :sm="12" :xs="12" class="order-info-card"><p>{{orderInfo.payTypeCode=='ATM'?'匯款截止日期':'繳費截止日期'}}</p><h4>{{orderInfo.paid?'已付款':orderInfo.payExpireDate}}</h4></el-col>
                    </el-row>
                </el-card>

                <el-card style="margin: 10px;" class="cart-total-card" shadow="hover">
                    <el-row>
                        <el-col :lg="24" :xs="24"><h3>訂單詳細資料</h3>
                        </el-col>
                    </el-row>

                    <el-row style="font-weight: bold;color: #909399;margin-bottom: 10px;">
                        <el-col :lg="18" :xs="18">商品</el-col>
                        <el-col :lg="6" :xs="6" style="text-align: right">小計</el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <div>
                        <el-row v-for="(item, index) in orderDtlList" :key="index" class="cart-item">
                            <el-col :lg="18" :xs="18">
                                <div style="display: flex;justify-content: start;align-items: center">
                                    <span style="padding-right: 10px;">{{index+1}}.</span>{{item.storeName}} <span style="font-weight: bold;">x {{item.cartNum}}</span>
                                </div>
                            </el-col>
                            <el-col :lg="6" :xs="6" style="text-align: right">
                                <span class="price">{{item.cartNum*item.price |filter_addPricePrefix}}</span>
                            </el-col>
                        </el-row>
                    </div>
                    <el-divider></el-divider>
                    <el-row class="cart-total-item">
                        <el-col :lg="18" :xs="18">小計</el-col>
                        <el-col :lg="6" :xs="6" style="text-align: right"><span class="total-amt">{{orderInfo.proTotalPrice |filter_addPricePrefix}}</span></el-col>
                    </el-row>
                    <el-row class="cart-total-item">
                        <el-col :lg="18" :xs="18">運费</el-col>
                        <el-col :lg="6" :xs="6" style="text-align: right">
                            <span class="total-amt">{{orderInfo.freightPrice |filter_addPricePrefix}}</span>
                        </el-col>
                    </el-row>

                    <el-row class="cart-total-item cart-total">
                        <el-col :lg="18" :xs="18">總計</el-col>
                        <el-col :lg="6" :xs="6" style="text-align: right"><span class="total-amt">{{orderInfo.payPrice |filter_addPricePrefix}}</span></el-col>
                    </el-row>
                </el-card>

                <el-card style="margin: 10px;" class="cart-total-card" shadow="hover" body-style="{ padding-top: '0px' }">
                    <div slot="header" class="clearfix">
                        <h3 style="margin: 0px;">收货資訊</h3>
                    </div>

                    <el-row class="rcv-address-item">
                        姓名: {{orderInfo.rcvName}}
                    </el-row>
                    <el-row class="rcv-address-item">
                        電話: {{orderInfo.rcvPhone}}
                    </el-row>
                    <el-row class="rcv-address-item">
                        取貨門市: {{orderInfo.storeName}}
                    </el-row>
                    <el-row class="rcv-address-item">
                        地址: {{orderInfo.rcvAddress}}
                    </el-row>

                </el-card>
                <el-row type="flex" justify="center">
                    <!--<el-button type="warning" @click="go2Print">列印</el-button>-->
                    <el-button v-if="orderInfo.showToCancel" type="danger" @click="cancelOrder">取消訂單</el-button>
                    <el-button v-if="orderInfo.showToRefund" type="danger" @click="applyRefundDialog">申請退貨</el-button>
                    <el-button type="warning" @click="go2Orders">查看我的訂單</el-button>
                </el-row>

            </el-col>

            <el-col :lg="3" :md="3" class="hidden-sm-and-down">
                &nbsp;
            </el-col>
        </el-row>
        <el-dialog title="請選擇原因" :visible.sync="dialogFormVisible" class="dlg-address" close-on-click-modal="true">
            <el-form :model="reasonForm" status-icon>
                <el-form-item label="請選擇原因">
                    <el-select v-model="reasonForm.reason" clearable="true">
                        <el-option
                                v-for="(item,index) in reasonList"
                                :key="index"
                                :label="item"
                                :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="cancelDialog">取 消</el-button>
                <el-button size="small" type="primary" @click="confirmCancelOrder">確 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="請選擇原因" :visible.sync="dialogRefundFormVisible" class="dlg-address" close-on-click-modal="true">
            <el-form :model="reasonForm" status-icon>
                <el-form-item label="請選擇原因">
                    <el-select v-model="reasonForm.reason">
                        <el-option
                                v-for="(item,index) in reasonList"
                                :key="index"
                                :label="item"
                                :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="cancelApplyRefundDialog">取 消</el-button>
                <el-button size="small" type="primary" @click="confirmApplyRefundOrder">確 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {OrderDetail, OrderCancel, OrderRefund, CancelReasonList} from '@/request/api';
    // import {mapMutations} from 'vuex';
    export default {
        name: 'CheckOutRcv',
        components: {},
        data() {
            return {
                orderInfo: {},
                orderDtlList: [],
                totalAmt: 0,
                totalQty: 0,
                totalSumAmt: 0,
                freightFee: 0,
                addressList: [],//地址簿
                rcvAddress: this.$store.state.rcvAddress,//当前收货地址
                remark: '',//备注
                szfuLogo: require('@/assets/images/szfu_logo.png'),
                payChannel: 'ATM',//ATM 付款方式
                dialogFormVisible: false,
                dialogRefundFormVisible: false,
                reasonForm: {},
                reasonList: []
            }
        },
        methods: {
            getOrderInfo() {
                let _this = this;
                let id = _this.$route.query.tx;
                OrderDetail(id).then(res => {
                    if (res.code == 200) {
                        this.orderInfo = res.data;
                        this.orderDtlList = res.data.orderInfoList;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    // loading.close();
                    console.log(err);
                    this.$message.error('網路連線失敗，稍候請重試!');
                });
            },
            go2Print() {
                window.focus();
                window.print();
            },
            go2Orders() {
                this.$router.push('/user-center/orders');
            },
            cancelOrder(){
                this.dialogFormVisible = true;
                CancelReasonList().then(res => {
                    if (res.code == 200) {
                        this.reasonList = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                    this.$message.error('網路連線失敗，稍候請重試!');
                });
            },
            cancelDialog() {
                this.dialogFormVisible = false;
            },
            confirmCancelOrder(){
                const params = new URLSearchParams();
                params.append("id", this.orderInfo.id);
                params.append("reason", this.reasonForm.reason);
                OrderCancel(params).then(res => {
                    if (res.code == 200) {
                        this.dialogFormVisible = false;
                        this.$message.success("取消成功！");
                        this.go2Orders();
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                    this.$message.error('網絡請求失敗，稍候請重試!');
                });
            },
            applyRefundDialog(){
                this.dialogRefundFormVisible = true;
                CancelReasonList().then(res => {
                    if (res.code == 200) {
                        this.reasonList = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                    this.$message.error('網路連線失敗，稍候請重試!');
                });
            },
            cancelApplyRefundDialog() {
                this.dialogRefundFormVisible = false;
            },
            confirmApplyRefundOrder(){
                this.$confirm('確定要申請退貨嗎？訂單號：' + this.orderInfo.orderId).then(() => {
                    const params = new URLSearchParams();
                    params.append("id", this.orderInfo.id);
                    params.append("reason", this.reasonForm.reason);
                    OrderRefund({id:this.orderInfo.id,reason:this.reasonForm.reason}).then(res => {
                        if (res.code == 200) {
                            this.dialogRefundFormVisible = false;
                            this.$message.success("申請成功！");
                            this.go2Orders();
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch(err => {
                        console.log(err);
                        this.$message.error('網絡請求失敗，稍候請重試!');
                    });
                }).catch(() => {
                });
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.getOrderInfo();
            });
        },
        created() {
        }
    }
</script>

<style lang="less" scoped>
    .main-content {
        .cart-header {
            margin-bottom: 20px;
            text-align: center;
            background-color: black;
            color: #FFFFFF;
            padding-bottom: 20px;
        }
    }

    .order-info-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px auto;

        h4 {
            margin: 5px auto;
        }
    }

    .cart-item {
        border-bottom-style: inset;
        border-bottom-width: 1px;
        margin: 10px auto;
        min-height: 35px;
        display: flex;
        align-items: center;
        font-size: 14px;

        &:last-child {
            border-bottom-width: 0px;
            margin-bottom: 0px;
        }
    }

    .cart-item-mobile {
        align-items: start;
        margin: 15px auto;

        .el-row {
            display: flex;
            align-items: center;
            margin-bottom: 2px;
            padding-bottom: 3px;
            border-bottom-style: inset;
            border-bottom-width: 1px;

            &:last-child {
                border-bottom-width: 0px;
                margin-bottom: 5px;
            }
        }
    }

    .cart-total-card {
        font-size: 14px;

        .cart-total-item {
            border-bottom-style: inset;
            border-bottom-width: 1px;
            margin: 20px 1%;
            padding-bottom: 10px;

            :last-child {
                text-align: right;

                span {
                    font-weight: bold;
                }
            }
        }

        .cart-total {
            border: none !important;
            font-weight: bold;
        }
    }

    .price {
        color: #F56C6C;

    }

    .total-amt {
        color: #a73535;
        font-weight: bold;
    }

    .rcv-address-item {
        border-bottom-style: inset;
        border-bottom-width: 1px;
        margin: 10px 1%;
        padding-bottom: 10px;

        &:last-child {
            border-bottom-width: 0px;
            margin-bottom: 0px;
        }
    }

    .el-card__header {
        padding: 5px 20px;
    }

    /deep/ .el-card__body {
        padding: 0px 20px;
    }
</style>
